.component-badger-accordion {
  @apply border-b border-current;
}

.badger-accordion__header {
  @apply border-t border-current;
}

.badger-accordion-title {
  @apply pt-4 pb-5;
}

.js-badger-accordion-panel-inner {
  @apply pb-4;
}

.icon-link {
  @apply ml-3 inline-block;

  width: 18px;
  height: 18px;

  & svg {
    width: inherit;
    height: inherit;

    & path {
      stroke: currentColor;
      transition: stroke 0.1s ease-out;
    }
  }
}

@screen mo {
  .icon-link.small {
    width: 13px;
    height: 13px;
  }
}

.icon-link--back {
  @apply ml-0 mr-1 inline-block;
}

@screen lg {
  .icon-link--large {
    width: 28px;
    height: 28px;
  }
}

@screen xl {
  .icon-link--large {
    width: 35px;
    height: 35px;
  }
}

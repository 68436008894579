.intro {
  @apply bg-black;
}

@screen mo {
  .stage-intro {
    height: calc(100vh - 5rem);
  }
}

.intro__inner {
  height: calc(100vh - 6rem);
  @apply relative;
}

@media (min-aspect-ratio: 16/9) {
  .intro__inner {
    height: calc(100vh - 6rem);
    @apply relative;
  }
}

.intro__video {
  @apply !absolute h-full w-full object-cover object-center;
  & > video {
    @apply h-full w-full object-cover object-center;
  }
}

.animated-entry--fade {
  opacity: 0;
}

.animated-entry--fade.animate-in {
  opacity: 1;
  transition: opacity 2s 0.3s ease-out;
}

.animated-entry--subtle {
  opacity: 0;
  transform: translate(0, 20px);
}

.animated-entry--subtle.animate-in {
  opacity: 1;
  transform: none;
  transition: transform 1s ease-out, opacity 1s ease-out;
}


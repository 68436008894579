body {
  @apply font-sans font-light text-lg antialiased leading-normal;
}

button:focus {
  outline: none;
}

::selection {
  color: white;
  background-color: rgba(0,0,0,0.99);
}

.bg-black ::selection {
  color: black;
  background-color: rgba(255,255,255,0.99);
}

@import 'style/fonts';

/* purgecss start ignore */
@import 'tailwindcss/base';
/* purgecss end ignore */

@import 'style/defaults';

@import 'tailwindcss/components';

/* purgecss start ignore */
@import 'style/components/intro';
@import 'style/components/panel';
@import 'style/components/rte';
@import 'style/components/anchor';
@import 'style/components/button';
@import 'style/components/checkbox';
@import 'style/components/accordion';
@import 'style/components/management';
@import 'style/components/logos';

@import 'style/lib/animated-entry';

/* purgecss end ignore */
@import 'tailwindcss/utilities';

@import 'style/utilities/icons';
@import 'style/utilities/margin';
@import 'style/utilities/transitions';
@import 'style/utilities/transforms.css';

html {
  visibility: visible;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

[id] {
  scroll-margin-top: 150px;
}

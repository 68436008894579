.team-mobile .js-badger-accordion-panel-inner {
  @apply pb-0;
}

.founder {
  @apply flex flex-wrap w-full;
  & > div:nth-child(odd) > div:first-child {
    box-shadow: -1px 0 0 0 black;
  }
  & > div:nth-child(even) > div:last-child {
    box-shadow: -1px 0 0 0 black;
  }
  & img {
    transition: opacity 0.3s ease-in-out;
  }
}
.founder > div:hover img {
  @apply opacity-100;
}

.team {
  @apply flex flex-wrap w-full;
  & > div {
    @apply border-b;
  }
  & img {
    transition: opacity 0.3s ease-in-out;
  }
}
.team > div:hover img {
  @apply opacity-100;
}

.team > div:nth-child(2),
.team > div:nth-child(3n+5) {
  box-shadow: inset -1px 0 0 black;
}

.team > div:nth-child(3n+1) {
  box-shadow: inset -1px 0 0 black;
}

.team div:nth-child(3n+1):nth-last-child(-n+3),
.team div:nth-child(3n+1):nth-last-child(-n+3) ~ div {
  @apply border-b-0;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.modal-enter, .modal-leave-to {
  opacity: 0;
}

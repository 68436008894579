.button {
  @apply text-white text-xs tracking-xwide;
  @apply bg-black p-3 border border-white;
  @apply transition-all duration-200;

  &.inverse {
    @apply bg-white text-black border-black;
  }
}
.button:hover {
  @apply bg-white text-black;
  &.inverse {
    @apply bg-black text-white;
  }
}


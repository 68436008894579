.panel {
  @apply mx-auto;
  @apply px-6;
  max-width: 32.5rem; /* 520px */
}

@screen mo {
  .panel--fullbleed {
    @apply px-0;
  }
  .panel--mo {
    @apply mx-auto;
    @apply px-6;
    max-width: 32.5rem; /* 520px */
  }
}

@screen md {
  .panel {
    @apply px-12;
    max-width: 57.5rem; /* 920px */
  }
}

@screen lg {
  .panel {
    @apply px-20;
    @apply max-w-320; /* 1280 px */
  }
}

@screen xl {
  .panel {
    @apply px-24;
    @apply max-w-400; /* 1600px */
  }
}

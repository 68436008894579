.logo:nth-child(n+4) {
  @apply mt-16;
}
.menu .logo path {
  fill: white !important;
}
.stage-intersecting .stage-bright .logo path {
  fill: black;
}
.stage-intersecting .stage-dark .logo:not(.logo-shi) path {
  fill: white;
}
.bg-white.stage-half .logo path {
  fill: black !important;
}
.bg-black.stage-half .logo path {
  fill: white !important;
}
.bg-black .logo path,
.stage-intersecting .stage-dark .logo:not(.logo-shi) path {
  fill: white;
}

@screen mo {
  .bg-white.stage-half .logo path {
    fill: black;
  }
  .bg-black.stage-half .logo path {
    fill: white;
  }
  .stage-intersecting .stage-bright .logo path {
    fill: black !important;
  }
  .stage-intersecting .stage-dark .logo:not(.logo-shi) path {
    fill: white !important;
  }
}

.checkbox {
  user-select: none;
  cursor: pointer;

  & input {
    display: none;
  }

  & label {
    @apply flex flex-row;
    cursor: pointer;

    & a {
      border-bottom: 1px dotted currentColor;

      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  & label:before {
    @apply block w-6 h-6 bg-white border-2 border-black rounded mr-3 cursor-pointer flex-none;
    content: '';
  }

  & input:checked + label:before {
    @apply bg-gray-700
  }

  &.checkbox input:checked + label:before {
    background-image: url(~@/assets/svg/icons/icon-checkmark.svg);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 50%;
  }

  & label span {
    @apply block relative;
    top: -1px;
  }
}

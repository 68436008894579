.rte h2 {
  @apply font-normal text-2xl;
  @apply leading-normal tracking-xwide;
  @apply -mt-1 mb-6;
}

.rte h3 {
  @apply font-normal text-xl;
  @apply leading-normal tracking-xwide;
  @apply mt-24 lg:mt-32 xl:mt-40 mb-8;
}

.rte a {
  @apply border-b border-current;
}

.rte p:not(:last-child) {
  @apply mb-5;
}

.rte ul {
  @apply list-disc list-inside;
}

.supervisory .rte p:not(:last-child) {
  @apply mb-4;
}

.supervisory .rte p + p {
  @apply mb-4;
}

.badger-accordion__panel .rte {
 @apply text-2xs;
}

@screen md {
  .rte h2 {
    @apply text-3xl;
    @apply -mt-3 mb-8;
  }

  .badger-accordion__panel .rte {
    @apply text-xs;
  }
}

@screen lg {
  .rte h2 {
    @apply text-4xl;
    @apply tracking-wide;
    @apply -mt-5 mb-10;
  }

  .badger-accordion__panel .rte {
    @apply text-sm;
  }
}

@screen xl {
  .rte h2 {
    @apply text-5xl;
    @apply leading-tight ;
    @apply mb-12;
  }

  .badger-accordion__panel .rte {
    @apply text-md;
  }
}

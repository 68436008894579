.anchor {
  width: calc(100%);
  background-image: linear-gradient(transparent 96%, currentColor 1px);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.anchor--hover:hover {
  width: calc(100%);
  background-image: linear-gradient(transparent 96%, currentColor 1px);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}


@font-face{
  font-family: 'Founders Grotesk';
  src: url('~@/assets/fonts/FoundersGroteskWeb-Regular.eot?#iefix');
  src: url('~@/assets/fonts/FoundersGroteskWeb-Regular.eot?#iefix') format('eot'),url('~@/assets/fonts/FoundersGroteskWeb-Regular.woff2') format('woff2'),url('~@/assets/fonts/FoundersGroteskWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
@font-face{
  font-family: 'Founders Grotesk';
  src: url('~@/assets/fonts/FoundersGroteskWeb-Light.eot?#iefix');
  src: url('~@/assets/fonts/FoundersGroteskWeb-Light.eot?#iefix') format('eot'),url('~@/assets/fonts/FoundersGroteskWeb-Light.woff2') format('woff2'),url('~@/assets/fonts/FoundersGroteskWeb-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

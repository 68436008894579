.mb { @apply mb-16; }
.pb { @apply pb-16; }
.pt { @apply pt-16; }
.mb--modest { @apply mb-8; }
.mb--stage { @apply mb-12; }
.mt { @apply mt-16; }

@screen md {
  .mb { @apply mb-24; }
  .pb { @apply pb-24; }
  .pt { @apply pt-24; }
  .mb--modest { @apply mb-12; }
  .mb--stage { @apply mb-16; }
  .mt { @apply mt-24; }
}

@screen lg {
  .mb { @apply mb-32; }
  .pb { @apply pb-32; }
  .pt { @apply pt-32; }
  .mb--modest { @apply mb-16; }
  .mb--stage { @apply mb-20; }
  .mt { @apply mt-32; }
}

@screen xl {
  .mb { @apply mb-40; }
  .pb { @apply pb-40; }
  .pt { @apply pt-40; }
  .mb--modest { @apply mb-18; }
  .mb--stage { @apply mb-24; }
  .mt { @apply mt-40; }
}
